

import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import FAQImage from "../../assets/PNG/Brix/FAQ.png";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null); // Track the currently expanded FAQ

  const faqData = [
    {
      question: "How secure is our data?",
      answer:
        "Your data is encrypted and stored securely on our servers, ensuring only authorized users have access.",
    },
    {
      question: "How does BRIX ensure the security of my documents?",
      answer:
        "BRIX uses advanced encryption methods, role-based access controls, and secure vaults to protect your documents.",
    },
    {
      question: "Is BRIX accessible from mobile devices?",
      answer:
        "Yes, BRIX is fully accessible from mobile devices with a responsive design and dedicated mobile apps.",
    },
    {
      question: "How does BRIX handle version control for construction documents?",
      answer:
        "BRIX automatically tracks changes with version control, allowing you to access older versions easily.",
    },
    {
      question: "Can BRIX handle large files like blueprints and 3D models?",
      answer:
        "The Default version does not provide this feature but customisations are available.",
    },
  ];

  // Toggle the expanded state for a specific index
  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div
      className="flex flex-col md:flex-row w-full min-h-screen items-center px-6 sm:px-8 lg:px-16 py-12 sm:py-16 lg:py-20"
      style={{
        background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
      }}
    >
      {/* Left Side: Static Image */}
      <div className="w-full md:w-1/2 flex justify-center items-center mb-12 md:mb-0">
        <img
          src={FAQImage}
          alt="Static FAQ Illustration"
          className="w-full max-w-[556px] h-auto flex-shrink-0"
        />
      </div>

      {/* Right Side: FAQ Content */}
      <div className="w-full md:w-1/2 flex flex-col justify-center space-y-8">
  <h1
  className="text-2xl sm:text-3xl md:text-4xl font-bold text-transparent bg-gradient-to-r from-[#41EAD4] to-[#000] bg-clip-text"
  style={{ fontFamily: "'Space Grotesk', sans-serif'"}}
  
>
<span className="bg-gradient-to-r from-[#41EAD4] via-[#41EAD4] to-[#000] bg-clip-text text-transparent ml-6">
    FAQs
  </span>

</h1>


        <ul className="space-y-8">
          {faqData.map((faq, index) => (
            <li
              key={index}
              className="border-b border-gray-300 pb-6 transition-all duration-300"
            >
              <button
                className="flex justify-between items-center w-full text-left text-lg focus:outline-none"
                onClick={() => toggleFAQ(index)}
              >
                <span className="flex-1 text-[#2B3E48] font-roboto font-bold text-[16px]">
                  {faq.question}
                </span>
                <DownOutlined
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  className={`text-[#2B3E48] text-[12px] flex-shrink-0 transition-transform duration-200 ${
                    expandedIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
              {expandedIndex === index && (
                <p className="mt-4 text-[#2B3E48] text-[14px] sm:text-[16px] font-roboto font-normal leading-relaxed">
                  {faq.answer}
                </p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;


