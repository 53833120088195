import React, { useState, useEffect } from "react";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer
      className={`w-full py-2 flex items-center justify-start ${
        isMobile ? "px-4 bg-transparent" : "px-12 bg-transparent"
      } relative`}
    >
      {/* Left Circle Background with Gradient and Blur */}
      <div
        className="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-[#E3F9F2] to-[#FFEBE8] opacity-20 blur-[10px] rounded-full"
        style={{ zIndex: -1 }}
      ></div>

      {/* Right Circle Background with Gradient and Blur */}
      <div
        className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-[#E3F9F2] to-[#FFEBE8] opacity-20 blur-[10px] rounded-full"
        style={{ zIndex: -1 }}
      ></div>

      {/* Footer Text */}
      <p
        className={`${
          isMobile
            ? "text-xs text-left leading-tight"
            : "text-sm text-left leading-normal"
        } text-[#11222C] font-roboto font-normal relative z-10`}
      >
        PEMS Digital Technologies Pvt. Ltd. © Copyright. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
