import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Company from "./components/Company";
import ReadMore from "./components/ReadMore";
import Products from "./components/Products";
import Careers from "./components/Careers";
import JobOpportunities from "./components/Careerpage/JobOpportunities";
import CandidateForm from "./components/ui/Forms/CandidateForm/CandidateForm";
import EmptyState from "./components/ui/EmptyState/EmptyState";
import Services from "./components/Services";
import News from "./components/News";
import ContactForm from "./components/ContactUs";
import Industries from "./components/Industries";
import IndustriesViewAll from "./components/IndustriesViewAll";
import Privacy from "./components/Privacy";
import Brix from "./components/Brix/Main";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import ThankYou from './components/Brix/ThankYou'

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      console.log(process.env.REACT_APP_BACKEND_URL, "backend-url");
      ReactGA.initialize(process.env.REACT_APP_GTM_ID_TWO);
    }
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Brix" element={<Brix />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/News-&-Insights" element={<News />} />
          <Route
            path="/News-&-Insights/read-more/:id/:heading"
            element={<ReadMore />}
          />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Industries" element={<Industries />} />
          <Route
            path="/Industries/View-all-Industries"
            element={<IndustriesViewAll />}
          />
          <Route path="Careers/jobs/:category" element={<JobOpportunities />} />
          <Route path="Careers/jobs/:category/cv" element={<CandidateForm />} />
          <Route path="/emptyState" element={<EmptyState />} />
          <Route path="/Contact" element={<ContactForm />} />
          <Route path="/Privacy-Policy" element={<Privacy />} />
          {/* <Route path="/test" element={<test/>} /> */}
          <Route path="/Brix" element={<Brix />} />
          <Route path="/Brix/Thankyou" element={<ThankYou />} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
