
// import React, { useState, useEffect } from "react";
// import { Spin } from "antd";
// import axios from "axios";
// import ReactGA from "react-ga4";

// const Subscribe = () => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Function to validate email format
//   const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     setIsLoading(true);
//     e.preventDefault();

//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       setSuccess("");
//       setIsLoading(false);
//       return;
//     }

//     setError("");
//     setSuccess("");

//     const data = JSON.stringify({ email, position: "top" });
//     const config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
//       headers: { "Content-Type": "application/json" },
//       data: data,
//     };

//     try {
//       const response = await axios.request(config);
//       ReactGA.event({
//         category: "subscriptions",
//         action: "submit",
//         label: "brix-subscription-top",
//         value: email,
//         transport: "xhr",
//       });
//       setSuccess("Subscription successful!");
//       setEmail("");
//     } catch (err) {
//       if (err.response && err.response.status === 400) {
//         setSuccess("We Already Have Your Email, Stay Tuned.");
//       } else {
//         setError("Please try again later.");
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div
//       className={`flex flex-col items-center ${
//         isMobile ? "py-12 px-6" : "py-20 px-10"
//       } text-center bg-[#11222C]`}
//     >
//       {/* Heading */}
//       <h1 className={`font-bold ${isMobile ? "text-3xl mb-4" : "text-4xl mb-6"} text-center font-['Space_Grotesk'] bg-gradient-to-br from-[#FFFFFF] to-[#41EAD4] bg-clip-text text-transparent`}>
//   Secure. Efficient. Accessible.
// </h1>


//       {/* Paragraph */}
//       <p className={`text-[#F8F9FB] ${isMobile ? "text-sm mb-6" : "text-lg mb-8"} text-center font-['Roboto'] font-normal`}>
//   Managing construction documents has never been easier. Our solution is designed to streamline workflows and enhance project efficiency.
// </p>


//       {/* Subscription Form */}
//       {/* <form
//           className="flex items-center justify-center w-full max-w-[400px] bg-white border border-gray-300 rounded-lg overflow-hidden"
//           onSubmit={handleSubmit}
//         >

         
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
//             style={{ height: "48px", minWidth: "0" }}
//           /> */}

//          {/* Subscribe Button */}
//          <button
//   type="submit"
//   disabled={isLoading}
//   className={`flex items-center justify-center px-6 py-2.5 gap-3 text-white font-semibold bg-[#FF6F61] rounded-lg transition duration-300 whitespace-nowrap ${
//     isLoading ? "cursor-not-allowed opacity-70" : ""
//   }`}
//   style={{
//     height: "48px",
//     maxwidth: "200px", // Fixed width for consistency
//   }}
// >
//   {isLoading ? <Spin size="small" /> : "Claim Your 14 Days FREE Trial"}
// </button>


//         {/* </form> */}


//       {/* Success Message */}
//       {success && (
//         <p className="text-green-500 text-sm font-light mt-4">{success}</p>
//       )}

//       {/* Error Message */}
//       {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
//     </div>
//   );
// };

// export default Subscribe;







import React, { useRef } from "react";

const Subscribe = ({ onScrollToDocument }) => {
  return (
    <div className="flex flex-col items-center py-12 px-6 text-center bg-[#11222C]">
      {/* Heading */}
      <h1 className="font-bold text-3xl mb-4 text-center  bg-gradient-to-br from-[#FFFFFF] to-[#41EAD4] bg-clip-text text-transparent">
        Secure. Efficient. Accessible.
      </h1>

      {/* Paragraph */}
      <p className="text-[#F8F9FB] text-sm mb-6 text-center font-['Roboto'] font-normal">
        Managing construction documents has never been easier. Our solution is designed to streamline workflows and enhance project efficiency.
      </p>

      {/* Subscribe Button */}
      <button
        type="button"
        onClick={onScrollToDocument} // Trigger scroll to DocumentManagement
        className="flex items-center justify-center px-6 py-2.5 gap-3 text-white font-semibold bg-[#FF6F61] rounded-lg transition duration-300"
        style={{
          height: "48px",
          Width: "200px",
        }}
      >
        Claim Your 14 Days FREE Trial
      </button>
    </div>
  );
};

export default Subscribe;


