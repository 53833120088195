import React, { useEffect, useState } from "react";
import {
  PushpinOutlined,
  TeamOutlined,
  LockOutlined,
  UploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import DashboardImage from "../../assets/PNG/Brix/Dashboard.png";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";

const TopScreen = ({ onScrollToDocument }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Monitor scroll to hide/show TopScreen
  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll > lastScrollTop && currentScroll > 100) {
        // Scrolling down and beyond 100px
        setIsVisible(false);
      } else if (currentScroll < lastScrollTop) {
        // Scrolling up
        setIsVisible(true);
      }

      lastScrollTop = currentScroll;
    };

    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Smooth scroll to subscription form
  const scrollToSubscribeForm = () => {
    const subscriptionForm = document.getElementById("subscription-form");
    if (subscriptionForm) {
      subscriptionForm.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div
      className={`relative flex flex-col items-center overflow-hidden ${
        isMobile ? "px-4" : "px-4 sm:px-8 lg:px-16"
      }`}
      style={{
        width: "100%",
        background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
      }}
    >
      {/* Main Content */}
      <div
        className={`text-center ${isMobile ? "px-4" : "sm:px-8 lg:px-16"}`}
        style={{ maxWidth: "1069px" }}
      >
        {/* Main Heading */}
        <h1
          className={`text-[28px] sm:text-[36px] lg:text-[72px] font-bold text-center mb-1 mt-4 ${
            isMobile ? "text-[28px]" : ""
          }`}
        >
          <span className="text-[#000000] mb-8">Organise Better.</span>
          <br />
          <span className="bg-gradient-to-r from-[#41EAD4] to-[#454545] bg-clip-text text-transparent">
            Access Faster.
          </span>
        </h1>

        {/* Subtitle */}
        <p
          className={`text-[12px] sm:text-[14px] lg:text-[16px] font-normal text-[#768090] font-['Roboto'] leading-normal mt-2 text-center ${
            isMobile ? "text-[12px]" : ""
          }`}
        >
          The Only One Document Management System That Your Business Needs!
        </p>
      </div>

      {/* Button */}

      {/* Button */}
      <div className="flex justify-center items-center">
        <button
          className="flex items-center justify-center px-6 py-2.5 gap-3 text-white font-semibold bg-[#FF6F61] rounded-lg transition duration-300"
          onClick={onScrollToDocument}
          style={{
            height: "48px",
            Width: "200px",
          }}
        >
          Claim Your 14 Days FREE Trial <span className="text-lg">↓</span>
        </button>
      </div>
      {/* Video Component */}
      <div
        className={`mt-8 relative w-full ${
          isMobile ? "max-w-[100%]" : "max-w-[1028px]"
        } 
  rounded-[11.506px] border-3 border-[#41EAD4] overflow-hidden`}
        style={{ aspectRatio: "16/9" }}
      >
        <Vimeo
          video="1045235320"
          responsive
          color="#ff6f61"
          showPortrait={false}
          showTitle={false}
          className="rounded-[11.506px]"
        />
      </div>

     {/* Left Side Icons */}
<div
  className={`absolute ${
    isMobile ? "top-[15%] left-[5%]" : "top-[15%] left-[5%]"
  } w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] lg:w-[50px] lg:h-[50px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md`}
>
  <UploadOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
</div>
<div
  className={`absolute ${
    isMobile ? "top-[30%] left-[10%]" : "top-[35%] left-[10%]"
  } w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] lg:w-[50px] lg:h-[50px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md`}
>
  <PlusCircleOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
</div>

{/* Right Side Icons */}
<div
  className={`absolute ${
    isMobile ? "top-[10%] right-[5%]" : "top-[5%] right-[8%]"
  } w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] lg:w-[50px] lg:h-[50px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md`}
>
  <LockOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
</div>
<div
  className={`absolute ${
    isMobile ? "top-[25%] right-[10%]" : "top-[25%] right-[5%]"
  } w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] lg:w-[50px] lg:h-[50px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md`}
>
  <TeamOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
</div>
<div
  className={`absolute ${
    isMobile ? "top-[35%] right-[15%]" : "top-[20%] right-[17%]"
  } w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] lg:w-[50px] lg:h-[50px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md`}
>
  <PushpinOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
</div>

      {/* Subscription Form */}
      <div id="subscription-form" className=""></div>
    </div>
  );
};

export default TopScreen;
