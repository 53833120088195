import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import axios from "axios";
import Document from "../../assets/PNG/Brix/Document.png";

const DocumentManagement = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [error, setError] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    org: "",
    general: "",
  });
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Validation functions
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhoneNumber = (phoneNumber) => /^[6-9]\d{9}$/.test(phoneNumber);
  const validateName = (name) => name.length >= 2 && name.length <= 20;
  const validateOrg = (org) => org.length >= 2 && org.length <= 20;

  // Mobile view listener
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError({ name: "", phoneNumber: "", email: "", org: "", general: "" });
    setSuccess("");

    const newError = {};
    if (!validateName(name))
      newError.name = "Name should be between 2 and 20 characters.";
    if (!validatePhoneNumber(phoneNumber))
      newError.phoneNumber = "Please enter a valid phone number.";
    if (!validateOrg(org))
      newError.org = "Organization name should be between 2 and 20 characters.";
    if (email && !validateEmail(email))
      newError.email = "Please enter a valid email address.";

    if (Object.keys(newError).length > 0) {
      setError(newError);
      setIsLoading(false);
      return;
    }

    const payload = {
      ...(name && { name }),
      ...(phoneNumber && { phoneNumber }),
      ...(email && { email }),
      ...(org && { org }),
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(payload),
    };

    try {
      const response = await axios.request(config);
      if (response.status === 201) {
        setSuccess("Subscription successful!");
        navigate("/Brix/Thankyou");
        setName("");
        setPhoneNumber("");
        setEmail("");
        setOrg("");
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (err) {
      if (
        err.response?.data?.error?.message ===
        "phone-number-already-exists-with-us"
      ) {
        setError((prev) => ({
          ...prev,
          phoneNumber: "Phone number already exists with us.",
        }));
        message.error("Phone number already exists with us.");
      } else {
        setError((prev) => ({
          ...prev,
          general: "An error occurred. Please try again later.",
        }));
        message.error("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`max-w-[1540px] bg-[#11222C] text-white flex flex-col md:flex-row items-start justify-between px-6 lg:px-12 py-8 md:py-16 mx-auto ${
        isMobile ? "space-y-6" : "space-x-12"
      }`}
    >
      {/* Left Section */}
      <div
        className={`flex flex-col items-start space-y-4 text-left w-full ${
          isMobile ? "items-center justify-center text-center" : "md:w-[50%]"
        }`}
      >
        <h2
          className={`text-transparent bg-gradient-to-r from-[#FFF] to-[#41EAD4] bg-clip-text text-[28px] sm:text-[46px] font-semibold leading-snug md:leading-[1.2] mb-6 ${
            isMobile ? "text-center" : "text-left"
          }`}
        >
          Ready to transform your <br /> Document Management?
          <p className="text-white text-lg sm:text-xl font-normal m-2">
            Sign up for a free trial today!
          </p>
        </h2>

        <div
  className={`flex justify-center ${
    isMobile ? "w-full" : "w-[599px]"
  } pt-[0.31px] rounded-[9.877px] border-[#41EAD4]`}
>
  <div
    className={`self-start w-full rounded-md`}
    style={{
      height: isMobile ? "280px" : "358px", 
      backgroundImage: `url(${Document})`,
      backgroundSize: isMobile ? "contain" : "cover", // Use "contain" for mobile to show the full image
      backgroundPosition: "center", 
      backgroundRepeat: "no-repeat", 
    }}
  />
</div>


      </div>

      {/* Right Section (Form) */}
      <div
        className={`flex flex-col items-start justify-between w-full ${
          isMobile ? "w-full" : "md:w-[50%] md:pl-12"
        }`}
      >
        <form
          className={`w-full max-w-[559px] h-[551px] flex-shrink-0 bg-[#F8FEFD] text-black p-6 md:p-8 rounded-[12px] shadow-lg ${
            isMobile ? "h-auto" : ""
          }`}
          onSubmit={handleSubmit}
        >
          <h3 className="text-xl sm:text-2xl font-semibold mb-6">
            Claim your 14 days FREE Trial
          </h3>

          <div className="mb-4">
            <input
              type="text"
              aria-label="Name"
              placeholder="Name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {error.name && (
              <p className="text-red-500 text-sm mt-1">{error.name}</p>
            )}
          </div>

          <div className="mb-4">
            <input
              type="text"
              aria-label="Phone Number"
              placeholder="Phone Number *"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {error.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">{error.phoneNumber}</p>
            )}
          </div>

          <div className="mb-4">
            <input
              type="email"
              aria-label="Email"
              placeholder="Email (optional)"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {error.email && (
              <p className="text-red-500 text-sm mt-1">{error.email}</p>
            )}
          </div>

          <div className="mb-4">
            <input
              type="text"
              aria-label="Organization Name"
              placeholder="Organization Name *"
              value={org}
              onChange={(e) => setOrg(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {error.org && (
              <p className="text-red-500 text-sm mt-1">{error.org}</p>
            )}
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-3 text-white bg-[#FF6F61] font-semibold rounded-md transition ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? <Spin size="small" /> : "Avail Now"}
          </button>
        </form>

        {success && <p className="text-green-500 text-sm mt-4">{success}</p>}
        {error.general && (
          <p className="text-red-500 text-sm mt-4">{error.general}</p>
        )}
      </div>
    </div>
  );
};

export default DocumentManagement;
