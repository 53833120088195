import React, { useState, useEffect, useRef } from "react";
import gif1 from "../../assets/GIF/LoginBrix.gif";
import gif2 from "../../assets/GIF/Vault.gif";
import gif3 from "../../assets/GIF/Task.gif";
import gif4 from "../../assets/GIF/VersionHistory.gif";
import gif5 from "../../assets/GIF/Teams.gif";





import { ReactComponent as Icon1 } from "../../assets/svg/Icon1.svg";
import { ReactComponent as Icon2 } from "../../assets/svg/Icon2.svg";
import { ReactComponent as Icon3 } from "../../assets/svg/Icon3.svg";
import { ReactComponent as Icon4 } from "../../assets/svg/Icon4.svg";
import { ReactComponent as Icon5 } from "../../assets/svg/Icon5.svg";

const Benefits = ({ onScrollToDocument }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const iconRef = useRef(null);
  const containerRef = useRef(null);

  const benefits = [
    {
      title: "Robust Access Control & Secure Onboarding",
      description:
        "BRIX offers a seamless onboarding process with role-based access control, ensuring users have appropriate permissions. Granular security settings and an advanced authentication flow safeguard sensitive data, enabling secure and efficient collaboration.",
      gif: gif1,
      icon: <Icon1 />,
    },
    {
      title: "Security Vault",
      description:
        "Store confidential documents in a PIN-protected vault, accessible only to authorized users. Timed sessions and controlled sharing add extra layers of security, making it a trusted space for sensitive files.",
      gif: gif2,
      icon: <Icon2 />,
    },
    {
      title: "Task Management",
      description:
        "Easily assign, track, and prioritize tasks with BRIX’s intuitive interface. Flexible editing and teamwide assignment ensure accuracy and collaboration, keeping workflows organized and efficient.",
      gif: gif3,
      icon: <Icon3 />,
    },
    {
      title: "Document Management with Version Control",
      description:
        "Track document versions effortlessly with BRIX. Access detailed change histories, manage permissions, and retrieve older versions as needed, ensuring seamless collaboration and continuity.",
      gif: gif4,
      icon: <Icon4 />,
    },
    {
      title: "Team Management",
      description:
        "BRIX simplifies team management with easy user organization, custom roles, and permissions. Teams can create tasks, share documents, and collaborate effectively, aligning with project needs securely.",
      gif: gif5,
      icon: <Icon5 />,
    },
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    const sections = document.querySelectorAll(".benefit-section");
    const container = containerRef.current;
    const icon = iconRef.current;

    let active = activeIndex;

    // Determine the currently active section
    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        active = index;
      }
    });

    setActiveIndex(active);

    // Align the icon with the middle of the active section
    const containerRect = container.getBoundingClientRect();
    const iconStyle = icon.style;

    if (containerRect.top > window.innerHeight / 2) {
      iconStyle.top = `${containerRect.top + window.scrollY + 10}px`;
    } else if (containerRect.bottom < window.innerHeight / 2) {
      iconStyle.top = `${containerRect.bottom + window.scrollY - 80}px`;
    } else {
      iconStyle.top = `50%`;
      iconStyle.transform = "translateY(-50%)";
    }
  };

  useEffect(() => {
    // Call handleScroll once on component mount
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeIndex]);

  return (
    <div className="bg-gradient-to-r from-[#E3F9F2] to-[#FFEBE8] min-h-screen flex flex-col items-center py-20 p-4">
      <div
        className="relative max-w-[1280px] w-full rounded-2xl bg-white shadow-xl p-8"
        ref={containerRef}
      >
        <h1 className="text-transparent bg-gradient-to-r from-[#41EAD4] to-[#000000] text-3xl sm:text-5xl font-bold text-center bg-clip-text mt-16 mb-16">
          Our Benefits
        </h1>

        {/* Vertical Line */}
        <div
          className="absolute left-[1rem] top-0 bottom-0 w-[4px] z-10 mt-12 mb-16 ml-6"
          style={{
            background: "linear-gradient(to bottom, #D1F8F0, #007768, #D1F8F0)",
          }}
        ></div>

        {/* Sticky Scrollable Icon */}
        {/* <div
          ref={iconRef}
          className={`fixed ${
            isMobile ? "left-[2rem]" : "left-[8rem]"
          } z-20 transition-transform duration-300`}
          style={{ width: "100px", height: "76.518px" }}
        >
          {benefits[activeIndex]?.icon}
        </div> */}



        {/* Sticky Scrollable Icon */}
<div
  ref={iconRef}
  className={`fixed ${
    isMobile ? "left-[2rem]" : "left-[8rem]"
  } z-20 transition-transform duration-300 flex items-center justify-center`}
  style={{
    width: "80px", // Adjust width for the circular icon
    height: "80px", // Adjust height for the circular icon
    // backgroundColor: "#E3F9F2", // Background color for the circle
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
  }}
>
  {benefits[activeIndex]?.icon}
</div>


        {/* Benefit Sections */}
        <div className="space-y-12 sm:space-y-36 px-4 sm:px-6 w-full ml-6 sm:ml-24">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="relative flex flex-col sm:flex-row items-start gap-6 sm:gap-16 benefit-section"
            >
              {/* Left Side (Text) */}
              <div className="w-full sm:w-1/2">
                <h2 className="text-[#007768] text-2xl sm:text-3xl font-bold mb-4 ml-4 sm:ml-6">
                  {benefit.title}
                </h2>
                <p
                  className="text-[#5C5C5C] text-base sm:text-lg leading-relaxed mb-6 ml-4 sm:ml-6"
                  style={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {benefit.description}
                </p>
                <button
                  className="mt-4 flex items-center justify-start gap-2 px-6 py-2 border-2 border-[#FF6F61] text-[#FF6F61] bg-transparent rounded-full ml-4 sm:ml-6"
                  onClick={onScrollToDocument}
                  style={{
                    padding: "10px 24px",
                    gap: "12px",
                    borderRadius: "999px",
                    border: "1px solid #FF6F61",
                  }}
                >
                  Claim Free Access
                </button>
              </div>

              {/* Right Side (Image) */}
              <div
                className={`${
                  isMobile ? "w-full flex justify-center" : "sm:w-1/2"
                }`}
              >
                <div
                  className="w-[240px] sm:w-[323px] h-[200px] sm:h-[300px] bg-cover bg-center rounded-lg"
                  style={{ backgroundImage: `url(${benefit.gif})` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
