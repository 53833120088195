
import React, { useRef } from "react";
import Navbar from '../Brix/Navbar';
import TopScreen from '../Brix/TopScreen';
import Subscribe from './Subscribe';
import KeyFeatures from './KeyFeatures';
import KeyFeaturesCard from './KeyFeaturesCard';
import OurBenefits from './OurBenifits';
import SlideImage from './SlideImage';
import WeHeardYou from './WeHeardYou';
import WeHeardYouCard from './WeHeardYouCard';
import ArchitectsAndDesigners from './ArchitectsAndDesigners';
import FAQ from './Faq';
import ContactUs from './ContactUs';
import Footer from './Footer';
import ProductDetails from './ProductDetails';
import DocumentManagement from './DocumentManagement';

const Main = () => {
  const documentRef = useRef(null);

  // Function to scroll to the DocumentManagement section
  const scrollToDocument = () => {
    if (documentRef.current) {
      documentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Navbar onScrollToDocument={scrollToDocument} />
      <TopScreen onScrollToDocument={scrollToDocument} />
      <Subscribe onScrollToDocument={scrollToDocument} />
      <div id="KeyFeatures">
        <KeyFeatures />
      </div>
      <KeyFeaturesCard />
      <div id="OurBenefits">
        <OurBenefits  onScrollToDocument={scrollToDocument} />
      </div>
      <SlideImage />
      <div id="WeHeardYou">
        <WeHeardYou />
        <WeHeardYouCard />
      </div>
      <div id="ArchitectsAndDesigners">
        <ArchitectsAndDesigners />
      </div>
      <div ref={documentRef}>
        <DocumentManagement />
      </div>
      <FAQ />
      <ProductDetails />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Main;

