import React, { useEffect } from "react";
import Header from "../../components/Brix/Navbar"; // Adjust the import path for your Header component
import Footer from "../../components/Brix/Footer"; // Adjust the import path for your Footer component
import ProductDetails from "../../components/Brix/ProductDetails"; // Adjust the import path for ProductDetails component
import ContactUs from "../../components/Brix/ContactUs"; // Adjust the import path for ContactUs component
import ThankYouImage from "../../assets/PNG/Brix/ThankYou.png"; // Adjust the image path

const ThankYouHeader = () => {
  // Scroll to top on component load
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div
      className="w-[1540px] h-auto flex flex-col items-center justify-between mx-auto"
      style={{
        background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
      }}
    >
      {/* Header */}
      <div className="w-full w-[1540px]">
        <Header />
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center text-center w-full max-w-[1540px]">
        <p className="w-[737px] text-[#59616E] text-center font-sans text-lg font-normal leading-[34px] mb-6">
          We've successfully set up your trial account. Please check your email to verify your account and get started!
        </p>

        <h1 className="text-[42px] font-semibold text-[#1B2028] leading-[50px] mb-2">
          Thank You for Signing Up!
        </h1>

        <p className="text-[42px] font-semibold text-[#1B2028] leading-[50px] mb-4">
          Your 14-Day Free Trial Awaits!
        </p>

        <p className="w-[737px] text-[#768090] text-center font-sans text-lg font-normal leading-[24px] mb-8">
          Start exploring BRIX and experience seamless collaboration like never before!
        </p>

        <img
          src={ThankYouImage}
          alt="Thank You"
          className="w-[485px] h-[396.5px] flex-shrink-0"
        />
      </div>

      {/* Additional Sections */}
      <div className="w-full flex flex-col items-center mt-12">
        <ProductDetails />
        <ContactUs />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ThankYouHeader;

