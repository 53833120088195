
import React, { useState } from "react";
import { motion } from "framer-motion";
import WeHeardYouCard from "./WeHeardYouCard";

const cardData = [
  {
    id: 1,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar1.png"),
        text: "Handling documents manually in our projects has led to errors, miscommunication, and even data loss. The risk of misplacing important files has been stressful, and keeping track of compliance-related paperwork has been a challenge.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX offers a secure solution for managing all project-related documents. Teams can ensure workflows and compliance are seamless. Clients can rest assured their data is secure, organized, and accessible.",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
        text: "Our team often experiences delays due to the difficulty of retrieving and sharing up-to-date information. When project documents aren’t easily accessible, it leads to miscommunication, poor decision-making, and increased project costs.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX's system enhances collaboration with real-time document access and sharing. Whether in the office or on-site, teams can retrieve and update documents instantly, reducing delays, improving communication, and ultimately helping to cut costs.",
      },
    ],
  },
  {
    id: 3,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar3.png"),
        text: "Managing a large volume of construction documents like blueprints, contracts, and invoices has been a constant struggle. Disorganization and version control issues slow down progress, and it’s frustrating to lose time looking for the latest version of a file.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX provides a system that centralizes all your documents, ensuring everything is securely stored, easily accessible, and up-to-date. Our version control feature ensures precision, while advanced search tools make file retrieval quick and efficient.",
      },
    ],
  },
];

const WeHeardYou = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  return (
    <div className="relative flex flex-col items-center bg-transparent px-4 py-6 sm:px-8 md:px-16 lg:px-24 mb-8">
    {/* Heading */}
    <h1 className="text-center  font-bold text-transparent bg-gradient-to-r from-[#41EAD4] to-[#000] bg-clip-text text-[52px]  mb-8">
      We Heard You!
    </h1>

  


      {/* Top Row */}
      <div className="flex flex-col md:flex-row gap-6 w-full justify-center items-stretch">
        {cardData.slice(0, 2).map((card, index) => (
          <motion.div
            key={card.id}
            className="w-full sm:w-[90%] md:w-[45%] lg:w-[40%] max-w-[630px] flex-shrink-0 rounded-lg border border-gray-300 bg-white shadow-md"
            initial={!animationComplete ? { opacity: 0, x: index === 0 ? -200 : 200 } : {}}
            animate={!animationComplete ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5 }}
            onAnimationComplete={() => setAnimationComplete(true)}
          >
            <WeHeardYouCard inputs={card.inputs} />
          </motion.div>
        ))}
      </div>

      {/* Spacer */}
      <div className="h-8 sm:h-10 md:h-16 lg:h-20"></div>

      {/* Bottom Row */}
      <motion.div
        key={cardData[2].id}
        className="w-full sm:w-[90%] md:w-[60%] lg:w-[45%] max-w-[630px] flex-shrink-0 rounded-lg border border-gray-300 bg-white shadow-md relative z-10"
        initial={!animationComplete ? { opacity: 0, y: 100 } : {}}
        animate={!animationComplete ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.5 }}
      >
        <WeHeardYouCard inputs={cardData[2].inputs} />
      </motion.div>
    </div>
  );
};

export default WeHeardYou;


