
import React, { useState } from "react";
import Logo from "../../assets/PNG/Brix/Brix-Logo.png";
import { Spin } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";

const Navbar = ({ onScrollToDocument }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Validate email
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle subscription
  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      return;
    }

    setIsLoading(true);
    setError("");
    setSuccess("");

    const data = JSON.stringify({ email, position: "top" });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);

      if (response.status === 201) {
        ReactGA.event({
          category: "subscriptions",
          action: "submit",
          label: "brix-subscription-top",
          value: email,
          transport: "xhr",
        });

        window.location.hash = "#thanks";

        setSuccess("Thank you for subscribing 👍");
        setEmail(""); // Clear email input
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setSuccess("We Already Have Your Email, Stay Tuned.");
      } else {
        setError("Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Smooth scrolling function
  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav className="flex flex-wrap items-center justify-between w-full w-[1540px] mx-auto px-4 md:px-20 py-4 border-b border-[#D9DEE7] bg-white backdrop-blur-sm">
      {/* Logo Section */}
      <div className="flex items-center justify-between w-full md:w-auto">
        <img
          src={Logo}
          alt="Logo"
          className="w-[127.66px] h-[48px] object-cover"
        />
        <button
          className="text-[#768090] text-xl focus:outline-none md:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? "✕" : "☰"}
        </button>
      </div>

      {/* Menu Items */}
      <div
        className={`${
          menuOpen ? "flex" : "hidden"
        } flex-col md:flex md:flex-row flex-1 justify-center items-center gap-8 text-[#768090] font-semibold text-sm md:text-[14px] leading-normal w-full md:w-auto mt-4 md:mt-0`}
      >
        <span onClick={() => handleScrollToSection("KeyFeatures")}>
          Features
        </span>
        <span onClick={() => handleScrollToSection("ArchitectsAndDesigners")}>
          For Teams
        </span>
        <span onClick={() => handleScrollToSection("WeHeardYou")}>
          Resources
        </span>
        <span onClick={() => handleScrollToSection("OurBenefits")}>
          Our Benefits
        </span>
        <span>EN, IN</span>
      </div>

      {/* Subscribe Section */}
      <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-4 mt-4 md:mt-0">
        {/* Submit Button */}
        <button
          type="button"
          disabled={isLoading}
          onClick={onScrollToDocument} // Trigger scroll to DocumentManagement
          className={`flex items-center justify-center h-12 w-48 font-semibold text-white bg-[#FF6F61] rounded-lg transition duration-300 ${
            isLoading ? "cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? <Spin size="small" /> : "Get Started for Free"}
        </button>

        {/* Success and Error Messages */}
        <div className="flex items-center w-full md:w-auto text-left">
          {error && <p className="text-red-500 text-xs ml-2">{error}</p>}
          {success && (
            <p className="text-green-600 text-sm font-semibold ml-2">
              {success}
            </p>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

